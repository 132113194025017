$maxmobile : 768px;
$tablet : 1024px;
$desktop : 1400px;
$midmobile : 480px;
$primary-color:#f5c515;
$primary-color-hover:#e0b314;
$white:#fff;
$black:#000;
$green:#34a853;

@mixin mobile {
    @media (max-width: #{$maxmobile}) {
      @content;
    }
}

@mixin mid-mobile {
  @media (max-width: #{$midmobile}) {
    @content;
  }
}
  
@mixin tablet {
    @media (max-width: #{$tablet - 1px}) {
      @content;
    }
}

@mixin desktop {
    @media (min-width: #{$tablet}) and (max-width: #{$desktop}) {
      @content;
    }
}

*{
    box-sizing: border-box;
    font-family: "Google Sans","Roboto",Arial,Helvetica,sans-serif;
}
%message-shared {

}

body {
    transition: padding-top 240ms cubic-bezier(0.4, 0, 0.2, 1);
    min-height:980vh;
    @include tablet {
      min-height: 10710px;
    }
}
section {
  width:100%;
  background-color:$white;
  box-shadow: 0 4px 14px rgba(0, 0, 0, .3);
  position: fixed;
  top:0;
  padding-top: 60px;
  min-height:100vh;
  .heading {
    color: #1d2129;
    display: block;
    font-size: 42px;
    line-height: 50px;
    margin: 20px auto 17px auto;
    max-width: 875px;
    text-align: center;
    @include tablet {
      font-size:35px;
    }
  }
  .sub-heading {
    color: #929598;
    display: block;
    font-size: 18px;
    font-weight: 300;
    line-height: 28px;
    margin: 0 auto 50px;
    max-width: 875px;
    text-align: center;
    @include tablet {
      font-size:16px;
    }
  }
  .content-heading {
    color: #1d2129;
    display: block;
    font-size: 28px;
    line-height: 38px;
    margin: 20px auto 17px auto;
    max-width: 875px;
    text-align: left;
    @include tablet {
      font-size: 22px;
      margin: 10px auto 5px auto;
    }
  }

  h1{
    color:#000;
    text-align:center;
  }
  &.active {
  position: relative;
  }
  &.fixed {
  position: fixed;
  }
  &.relative {
    position: relative;
    }
  &.section1 {
    z-index: 7;
    height:100vh;
    background: #f5f7f8;
    @include tablet {
      height: 800px;
      padding-top: 20px;
    }
    .heading {
      margin: 20px auto 17px auto;
      max-width: 875px;
     }
    .sub-heading {
      color: #929598;
      display: block;
      font-size: 18px;
      font-weight: 300;
      line-height: 24px;
      margin: 0 auto 50px;
      max-width: 875px;
      text-align: center;
    }
    .box {
      text-align: center;
      width:100%;
      float:left;
      .box-child {
        background: #fff;
        border: 1px solid #dadcde;
        border-radius: 8px;
        box-shadow: 0 0 16px 0 #ced0d4;
        height: 260px;
        margin: 12px;
        padding-left: 25px;
        padding-right: 25px;
        padding-top: 25px;
        text-align: center;
        width: calc(50% - 48px);
        float: left;
        @include tablet {
          width: 90%;
          margin: 0 5%;
          margin-bottom: 20px;
          height: 218px;
        }
        .heading{
          font-size: 20px;
          font-weight: 400;
          letter-spacing: initial;
          line-height: 28px;
        }
        input {
          background: #fff;
          border-radius: 4px;
          color: #5f6368;
          font-family: "Google Sans","Roboto";
          font-size: 16px;
          height: 48px;
          opacity: 1;
          width: 50%;
          max-width: 400px;
          padding: 0 14px;
          @include tablet {
            width:90%;
          }
        }
        button {
        font-family: "Google Sans","Roboto",Arial,Helvetica,sans-serif;
        -moz-osx-font-smoothing: grayscale;
        -webkit-font-smoothing: antialiased;
        text-rendering: optimizeLegibility;
        align-content: center;
        align-items: center;
        align-self: flex-start;
        border: 1px solid transparent;
        border-radius: 4px;
        display: inline-flex;
        flex-direction: row;
        flex-wrap: nowrap;
        font-size: 16px;
        font-weight: 500;
        height: 48px;
        justify-content: space-around;
        letter-spacing: .5px;
        margin: 8px 0;
        min-width: 96px;
        padding: 2px 24px 0;
        text-align: center;
        text-decoration: none;
        transition: background-color .2s, box-shadow .2s, color .2s;
        vertical-align: middle;
        background-color: #75d9f4;
        }
      }
      .logo {
        img {
            width: 250px;
            text-align: center;
            margin-top: 25px;
            @include tablet {
              width: 80%;
              margin-top: 25px;
              max-width: 240px;
            }
        }
      }
    }

  }
  &.section2 {
    z-index: 6;
    background: #f5f7f8;
    height:250vh;
    @include tablet {
      height:1600px; 
    }
    p{
      width:100%;
      text-align: center;
      color:$black;
      font-size: 42px;
      margin-bottom: 10px;
      line-height: 1;
      @include tablet {
        font-size:35px;
      }
    }
    h3{
      color:$green;
      font-size: 42px;
      text-align: center;
      width: 100%;
      line-height: 1;
      margin-bottom: 50px;
      @include tablet {
        font-size: 35px;
        margin-bottom: 0px;
      }
    }
    .unit {
      width:100%;
      float:left;
      border: 1px solid #000;
      height:200px;
      border-bottom:none;
      &:last-child{
        border-bottom:1px solid #000;
      }
      .image {
        width:50%;
        float:left;
        padding:20px;
        height:100%;
        img {
          width:200px;
        }
      }
      .content {
        width:50%;
        float:left;
        height:100%;
        padding: 20px 20px;
        h3 {
          color: #000;
          font-weight: 100;
          text-align: left;
          margin-bottom: 20px;
          font-size: 28px;
        }
        p{
          font-weight: 100;
          color: #444;
          font-size: 16px;
          text-align: left;
          line-height: 20px;
        }
      }
    }
    .content-container {
      margin-top:30px;
      .content {
        padding-top:30px;
        padding-left: 8%;
        @include tablet {
          flex: 0 0 100%;
          max-width: 100%;
        }
        h2{
          color: #1d2129;
          display: block;
          font-size: 28px;
          line-height: 38px;
          margin: 20px auto 17px auto;
          max-width: 875px;
          text-align: left;
          @include tablet {
            margin: 0px auto 5px auto;
            font-size: 22px;
          }
        }
        p{
          color: #929598;
          display: block;
          font-size: 18px;
          font-weight: 300;
          line-height: 24px;
          margin: 0 auto 30px;
          max-width: 875px;
          text-align: left;
          @include tablet {
            font-size: 16px; 
            margin-bottom:10px;
          }
      }
        
      }
      .image {
        position: relative;
        @include tablet {
          display:none;
        }
        img {
        position: absolute;
        z-index: 99;
        width:100%;
        }
        &::after {
          content: '';
          display: block;
          position: relative;
          background: #ee93ec;
          background-image: linear-gradient(90deg,#ee93ec 0%,#75d9f4 100%);
          height: 375px;
          left: 14%;
          top: 200px;
          z-index: 0;
          width: 86%;
        }
        &.right-align {
          &::after {
            left: -5%;
          }
        }
      }
    }
  }
  &.section3 {
    z-index: 5;
    height:180vh;
    @include tablet {
      height: 3000px;
    }
    
    .row {
      text-align: center;
      .content-heading {
        text-align: center;
      }
    }
  }
  &.section4 {
    height:100vh;
    z-index: 4;
    @include tablet {
      height: 850px;
    }
    .sub-heading,.heading {
      text-align:left;
    }
    img {
      width:70%;
    }
  }
  &.section5 {
    height:100vh;
    z-index: 3;
    @include tablet {
      height: 850px;
    }
    img {
      width:100%;
    }
    ul {
      list-style: none;
      li {
        color: #137333;
        -webkit-font-smoothing: antialiased;
        text-rendering: optimizeLegibility;
        font-size: 16px;
        font-weight: 600;
        letter-spacing: initial;
        line-height: 24px;
        margin-bottom: 10px;
      }
    }
    .heading {
      margin-bottom: 60px;
    }
  }
  &.section6 {
    z-index:2;
    height:150vh;
    @include tablet {
      height: 2610px;
    }
    .package-box {
      width:100%;
      float:left;
      margin-bottom: 20px;
      @include tablet {
        margin-bottom: 0px;
      }
      .package-container {
        border-radius: 10px;
        padding: 0px 0px;
        height: 410px;
        width: calc(33% - 10px);
        margin-right: 10px;
        float: left;
        border: 1px solid #adb5bd;
        background-color: #f8f9fa;
        @include tablet {
          width: 90%;
          margin: 0 5% 15px 5%;
        }
        .package {
          font-size: 18px;
          padding: 10px 10px 10px 10px;
          border-bottom: 1px solid #adb5bd;
          text-align: center;
          text-transform: uppercase;
          span {
            font-size: 12px;
            border: 1px solid #dae0e5;
            padding: 6px;
            border-radius: 7px;
            background-color: #dae0e5;
            color: #3F51B5;
          }
        }
        .fee {
          padding: 10px 15px;
          text-align: center;
          font-size: 12px;
          background-color: #17a2b8;
          color: #fff;
          span {
            font-size: 26px;
            margin-left: 2px;
          }
          .month {
            color: #fff;
            font-size: 10px;
          }
        }
        .discription {
          padding: 10px 15px 10px 15px;
          text-align: left;
          border-bottom: 1px solid #adb5bd;
        }
        .deviceno {
          padding: 10px 15px;
          font-size: 20px;
          text-align: center;
          border-bottom: 1px solid #adb5bd;
        }
        .device {
          padding: 10px 15px;
          font-size: 20px;
          text-align: center;
          border-bottom: 1px solid #adb5bd;
        }
        .sign-up {
          padding:10px 10px;
          text-align: center;
          button{
            font-family: "Google Sans","Roboto",Arial,Helvetica,sans-serif;
            -moz-osx-font-smoothing: grayscale;
            -webkit-font-smoothing: antialiased;
            text-rendering: optimizeLegibility;
            align-content: center;
            align-items: center;
            align-self: flex-start;
            border: 1px solid transparent;
            border-radius: 4px;
            display: inline-flex;
            flex-direction: row;
            flex-wrap: nowrap;
            font-size: 16px;
            font-weight: 500;
            height: 48px;
            justify-content: space-around;
            letter-spacing: .5px;
            margin: 8px 0;
            min-width: 96px;
            padding: 2px 24px 0;
            text-align: center;
            text-decoration: none;
            transition: background-color .2s, box-shadow .2s, color .2s;
            vertical-align: middle;
            background-color: #75d9f4;
          }
        }
      }
    }
    

  }
  &.section7 {
    z-index:1;
    background: #f5f5f5;
    height:100vh;
    @include tablet {
      height: 1100px;
    }
    .footer-heading {
      color: #000;
    cursor: default;
    letter-spacing: 2px;
    font-size: 12px;
    font-weight: 600;
    text-transform: uppercase;
    }
    ul{
      list-style: none;
      border: 0;
      font: inherit;
      font-size: 100%;
      margin: 24px 0 0 0;
      padding: 0;
      vertical-align: baseline;
      li {
        font-size: 14px;
        line-height: 16px;
        margin-top: 8px;
      }
    }
    .content{
      padding-bottom: 50px;
      border-bottom: 1px solid #6c757d;
    }
    p{
      font-size: 14px;
      line-height: 16px;
      margin-top: 8px;
    }
    .logo-trans{
      text-align: center;
      padding: 20px;
      img {
        width: 80px;
        padding: 10px 10px;
      }
    }
  }
}
.br-right {
  border-right:1px solid #000;
}
.slide-dots {
  position: fixed;
  z-index: 999;
  top: 50%;
  list-style: none;
  right:20px;
    li {
      width: 10px;
      height: 10px;
      background-color: #e9eaeb;
      border-radius: 10px;
      margin-bottom: 10px;
      &.active {
        background-color: #5f6673;
      }
    }
}
#map {
  height: 400px;  /* The height is 400 pixels */
  width: 100%;  /* The width is the width of the web page */
  @include tablet {
    margin-bottom: 20px;
  }
 }